<template>
<div id="scrollcontainer">
<div id="content">
		<h1 id="deepTopelius">Om DeepTopelius</h1>
		<div id="message" class="message smallerText">
			
            <strong>DeepTopelius</strong> består dels av den här webbsidan och dels av en maskininlärningsbaserad textgenerator som försöker skapa texter i samma stil som Zacharias Topelius, mera specifikt <em>Fältskärns Berättelser</em> och <em>Stjärnornas Kungabarn</em>. 
            <h3>Zacharias Topelius</h3>
            <strong>Zacharias Topelius</strong> (1818 - 1898) var en finlandssvensk författare och är en av Finlands mest kända författare. Bland hans mest kända verk återfinns <em>Fältskärns berättelser</em> och <em>Sagor för barn</em>.
            <br />Observera att ingen av texterna som presenteras här har författats av Topelius! Topelius verk har använt som träningsdata för textgeneratorn, men själva texterna är helt och hållet datorgenererade.
            
            <h3>Modellerna</h3>
            Textgeneratorn består av en maskininlärningsmodell tränad med <em><a href="http://runeberg.org/faltskar/" target="_blank">Fältskärns berättelser</a></em> och en tränad med <em><a href="http://runeberg.org/topstjarn/" target="_blank">Stjärnornas kungabarn</a></em>. Texterna från de båda verken finns gratis för allmänheten att läsa och ladda ner från <a href="http://runeberg.org/">Projekt Runeberg</a>.

            <br /><br />Maskininlärningsmodellen är ett neuralt nätverk, teckenbaserat med bidirektionella LTSM-lager (<a href="https://www.tensorflow.org/text/tutorials/text_generation" target="_blank">se liknande upplägg här</a>) med ungefär 8 miljoner parametrar inalles.
     
            <br />Givet en textsträng på ett visst antal tecken (100 i det här fallet) som matas in i modellen, "förutsäger" modellerna sannolikheterna för möjliga nästa tecken i sekvensen. Som träningsdata har använts sekvenser om 126 tecken (125 tecken som inmatning och det sista 126:e tecknet som resultat). 
            <br /><br />Modellen är skapad och tränad i maskininlärningsramverket <a href="https://tensorflow.org" target="_blank">TensorFlow (2.5)</a>. Arkitektur och hyperparametrar för den slutliga modellen valdes efter ett antal experiment med olika kombinationer av parametervärden.

            <h3>Textgenerering</h3>
            Som första inmatningsekvens väljs ett slumpmässigt första ord i en mening från det ursprungliga materialet (t.ex <em>Fältskärns berättelser</em>).
            <br />Modellen ger som resultat sannolikheterna för nästa tecken i sekvensen (dvs. sannolikheten för att nästa tecken är <em>a</em>, <em>b</em>, <em>c</em> osv. inklusive skiljetecken, mellanslag och såväl versaler som gemener); för att ge variation används inte det tecken som har högst sannolikhet direkt, utan <a href="https://towardsdatascience.com/how-to-sample-from-language-models-682bceb97277" target="_blank">temperature sampling</a> används för att välja nästa tecken. Det valda tecknet läggs till den ursprungliga inmatningssekvensen, och det första tecknet i sekvensen tas bort så att längden fortsättningsvis är 125 tecken - detta blir inmatningssekvensen till nästa iteration.
            <br />För nästa iteration läggs ett nytt tecken till på samma vis till inmatningsekvensen, och det första tecknet i inmatningsekvensen tas bort, för att skapa inmatningsekvensen till nästa iteration, osv.  
            <br />På så vis kan man skapa löpande text iterativt med modellen med önskad längd på texten.
            <br />Ett fiktivt exempel på hur textgenereringsprocessen kan se ut (_ används här för att representera mellanslag, för tydlighetens skull):
            <br /><div class="monospace">Fram_-> m
            <br />ram_m -> e
            <br />am_me -> d
            <br />m_med -> _ 
            <br />_med_-> s
            <br />med_s -> a 
            <br />ed_sa -> n
            <br />d_san -> n
            <br />_sann -> i 
            <br />sanni -> n
            <br />annin -> g
            <br />nning -> e
            <br />ninge -> n
            <br />ingen -> !</div>

            <br />Slutresultatet är då den ursprungliga inmatningsekvensen följt av alla tecken som modellen producerat, i exemplet ovan "Fram med sanningen!". Då textgeneratorn arbetar på teckenbasis, "ser" den inte ord och kan därför skapa nonsensord eller (av en tillfällighet) faktiska ord som inte förekommer i textmaterialet.
            Vissa kortare meningar (exempelvis "Nej, ers majestät") kan förekomma som sådant i källmaterialet. 
            
            <br /><br /> En viss variation i ortografin finns att notera i träningsdatan: materialet för <em>Stjärnornas kungabarn</em> som används här publicerades i slutet av 1800-talet, efter den sjätte upplagan av Svenska Akademiens Ordlista 1889 som introducerade en rad förändringar i stavningen för flertalet ord (exempelvis <em>stjerna/stjärna</em>, <em>hjerta/hjärta</em>, <em>hafva/hava</em>). 
                
            <br /><br />Ibland producerar textgeneratorn riktigt bra texter, ibland går det snett... några exempel på mindre lyckade - men eventuellt roande - texter som DeepTopelius producerat återges här:
            <ul>
                <li><em>Associera er yngjordörandegårdensymåligheterierård i vantro och hålla sin rika medvetande på en gång, och flickan fann sig ej längre inåt pannan.</em></li>
            </ul> 

            <h3>Webbsidan</h3>
            Webbsidan presenterar alltså meningar som textgeneratorn producerat. Webbsidan väljer slumpmässigt ett antal meningar (minst två och högst 10) från de genererade texterna och visar dem för användaren. Användaren kan se nya texter genom att trycka på knappen "Fram med sanningen, slyngel!" (en genuin replik från Fältskärns berättelser).
                
            <br /><br />Texterna har genererats på förhand, av vilka ett antal har valts ut för presentation på den här webbisdan. En mindre andel av texterna (ungefär 25%) har i viss mån redigerats manuellt, övriga har lagts till utan manuell redigering. Grammatik (särskilt genuskongruens) och nonsensord har korrigerats minimalt, och flertalet har lämnats okorrigerade i underhållingssyfte. Modellerna är helt och hållet teckenbaserade, och semantik eller sammanhang tas inte i beaktande; textgeneratorn försöker så gott den kan att skapa texter i liknande stil som Topelius, utan någon hänsyn till substans.
            <br /><br />Summa summarum: texterna som genereras är total nonsens :) (eller så får läsaren själv tolka vad de kan tänkas betyda...)
                
            <br /><br />Nya meningar läggs till och redigeras sporadiskt, när andan faller på. För närvarande finns c:a 700 meningar för webbsidan att välja mellan (kring 28 * 10<sup>27</sup> möjliga kombinationer av meningar) att välja mellan).

            <br /><br />Webbsidan är skapat med hjälp av webbramverket <a href="https://vuejs.org/" target="_blank">Vue 2.X</a>.
                    
            <h3>Vad är poängen med detta?</h3>    
            Det kan man fråga sig.
            <br />Ursprungligen var det fråga om ett experiment med textgenerering på svenska: en stor del av litteraturen och material online om språkteknologi kretsar kring engelska och de större språken, och vi ville helt enkelt prova hur väl man kunde tillämpa textgenereringsmetoder som använts för engelska på svenska. 
            <br />
            <h3>Dela texter</h3>    
            Ifall du stöter på särskilt intressanta eller underhållande textpassager som du vill dela med dig av, uppdateras adressfältet automatiskt med ett unikt identifikationsnummer; för att dela just den specifika texten, kopiera hela adressen inklusive identifikationsnumret.

            <h3>Rättigheter</h3>   
            <br />Fotografiet på Zacharias Topelius är taget från <a href="https://commons.wikimedia.org/wiki/File:Zacharias_Topelius_2.jpg" target="_blank">Wikimedia Commons</a>, fotograf okänd.
            <br /><br />Träningsmaterialet (<em>Fältskärns berättelser</em> och <em>Stjärnornas kungabarn</em>) hämtat från <a href="http://runeberg.org" target="_blank">Projekt Runeberg</a>. Ett stort tack till Projekt Runeberg för de otaliga timmar som digitaliseringsarbetet innebär!
            <br /><br />Ansvarig utgivare för webbsidan och modellerare: Michael Stormbom (michael@stroberock.com) vid Stroberock (<a href="https://www.stroberock.com/" target="_blank">www.stroberock.com</a>).
            <br /><br />Webbsidan och underliggande maskininlärningsmodeller © 2021 Stroberock. All rights reserved.
		</div>
		
		<router-link to="/" class="infoquestion">Tillbaka till huvudsidan</router-link>
        <div id="strobelogo"><a href="https://stroberock.com/" target="_blank"><img id="strobeimg" src="/assets/img/strobelogo_bw.png" alt="Skapat av Stroberock"/></a></div>
	</div>
    </div>
</template>

<script>

export default {
  name: 'About',
  data: () => ({}),
		methods: {
			
		},
	mounted: function() {
        document.getElementById("message").style.opacity = 1;
        document.getElementById("deepTopelius").style.opacity = 1;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
	created: function() {
       // document.getElementById("message").style.opacity = 1;
        // document.getElementById("deepTopelius").style.opacity = 1;       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
